<template>
  <div>
    <a
      @click="$router.back()"
      href="#"
      class="
        text-dark-75
        font-weight-bolder
        text-hover-primary
        mb-1
        font-size-lg
        d-flex
        flex-row
        align-items-center
      "
    >
      <i class="mdi mdi-chevron-left"></i>
      Kembali
    </a>
    <div class="card card-custom gutter-b" v-if="currentUjian">
      <div class="card-body">
        <div class="d-flex mb-9">
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex flex-column mr-3">
                <a
                  href="#"
                  class="
                    text-dark-75 text-hover-primary
                    font-size-h5 font-weight-bold
                    mr-3
                  "
                >
                Pertinjau Ujian
                </a>
                <a
                  href="#"
                  class="
                    text-dark-50 text-hover-primary
                    font-size-h7 font-weight-bold
                    mr-3
                  "
                >
                  {{ currentUjian.name }}
                </a>
              </div>

              <div class="my-lg-0 my-3">
                <button
                  v-b-toggle.deskripsi
                  class="
                    btn btn-light-info
                    font-weight-bolder font-size-sm
                    mr-2
                  "
                >
                  Lihat Deskripsi
                </button>
              </div>
            </div>
            <!--end::Title-->

            <!--begin::Content-->
            <div class="d-flex flex-wrap justify-content-between mt-1">
              <div class="d-flex flex-column flex-grow-1 pr-8">
                <div class="d-flex flex-wrap mb-4">
                  <a
                    href="#"
                    class="
                      text-dark-50 text-hover-primary
                      font-weight-bold
                      mr-lg-8 mr-5
                      mb-lg-0 mb-2
                    "
                  >
                    <i class="flaticon-clock-1 mr-2 font-size-lg"></i>
                    {{ currentUjian.time }} Menit
                  </a>
                  <a
                    href="#"
                    class="
                      text-dark-50 text-hover-primary
                      font-weight-bold
                      mr-lg-8 mr-5
                      mb-lg-0 mb-2
                    "
                    ><i class="flaticon2-calendar-2 mr-2 font-size-lg"></i>
                    {{ currentUjian.opening_time | moment("dddd, LL HH:mm") }}
                    -
                    {{ currentUjian.closing_time | moment("dddd, LL HH:mm") }}
                  </a>
                </div>

                <b-collapse id="deskripsi" class="mt-2">
                  <b-card>
                    <div v-html="currentUjian.description"></div>
                  </b-card>
                </b-collapse>
              </div>
            </div>
            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->

        <div class="separator separator-solid"></div>

        <!--begin::Items-->
        <div class="d-flex align-items-center flex-wrap mt-8">
          <!--begin::Item-->
          <div class="d-flex align-items-center mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-file-2 display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Jumlah Soal</span>
              <span class="font-weight-bolder font-size-h5">
                <span class="text-dark-50 font-weight-bold">
                  {{ currentUjian.question_pack_id.questions.length }}
                </span>
              </span>
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-users display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Jumlah Siswa</span>
              <span class="font-weight-bolder font-size-h5">
                <!--                    <span class="text-dark-50 font-weight-bold">$</span>-->
                <!-- {{ getTotalStudents }} -->
              </span>
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center mb-2 float-left">
            <span class="mr-4">
              <i
                class="flaticon-network display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Kelas</span>
              <div>
                <!-- <span
                      class="
                        label label-lg label-inline
                        font-weight-bold
                        py-4
                        label-light-info
                        mr-1
                      "
                      v-for="kelas in currentUjian.class_id"
                      :key="kelas"
                    >
                      {{ kelas }} 
                    </span> -->
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                  "
                >
                  {{ currentUjian.class_id.length }} Kelas
                </span>
              </div>
            </div>
          </div>
          <!--end::Item-->
        </div>
        <!--begin::Items-->
      </div>
    </div>
    <b-row class="justify-content-end">
      <b-col class="d-flex justify-content-end"> </b-col>
    </b-row>
    <b-row class="h-100 mt-10">
      <b-col col lg="9" md="12">
        <div :class="`card card-custom card-stretch gutter-b`">
          <div class="card-header border-0 py-5">
            <h3
              class="
                card-title
                align-items-start
                flex-column
                justify-content-center
              "
            >
              <span class="card-label font-weight-bolder text-dark">
                Soal {{ selectedIndex + 1 }} (
                {{
                  selectedQuestion.answer
                        .filter((item) => item.isCorrect)
                        .reduce((x, y) => x + y.point, 0)
                }}
                ) pts
              </span>
            </h3>
            <div class="card-toolbar"></div>
          </div>
          <div class="card-body d-flex flex-column">
            <div class="flex-grow-1 pb-5">
              <div v-html="selectedQuestion.question" class="mb-4"></div>
              <div
                v-if="selectedQuestion.type == 'multiple choice'"
                class="text-right mb-2"
              >
                <!-- [Pilih -->
                <!-- {{ selectedQuestion.answer.length }} jawaban] -->
              </div>
              <div class="jawaban"
               >
               <template v-if="selectedQuestion.type != 'essay'">
                 <div                  
                  :class="`card card-custom card-stretch border ${
                    jawaban.isCorrect
                      ? 'bg-light-primary text-primary border-primary'
                      : ''
                  }`"
                  v-for="(jawaban, i) in selectedQuestion.answer"
                  :key="`${selectedQuestion._id}${i}`"
                >
                  <div class="card-body d-flex flex-row p-4 align-items-center">
                    <b-form-checkbox
                      disabled
                      :name="`jawaban${selectedQuestion._id}`"
                      v-model="jawaban.selected"
                      size="lg"
                      class="align-items-start cursor-pointer"
                      v-if="selectedQuestion.type == 'multiple answer'"
                    ></b-form-checkbox>
                    <b-form-radio
                      disabled
                      :name="`jawaban${selectedQuestion._id}`"
                      :value="jawaban._id"
                      v-model="selectedQuestion.id_jawaban"
                      size="lg"
                      class="align-self-start cursor-pointer"
                      v-else
                    ></b-form-radio>
                    <div
                      v-html="
                        `<div class='col-auto'>${jawaban.answer}</div><div class='col justify-content-end'><p>(${jawaban.point}) point</p></div>`
                      "
                    ></div>
                    <b-col>
                      <b-row
                        class="justify-content-end"
                        v-if="jawaban.isCorrect"
                      >
                        <b-badge pill variant="success">Benar</b-badge>
                      </b-row>
                    </b-col>
                  </div>
                </div>
               </template>
                <b-form-group
                  v-if="selectedQuestion.type == 'essay'"
                  label="Jawaban"
                >
                  <b-form-textarea
                    disabled
                    :id="`textarea-jawaban-${i}`"
                    v-model="selectedQuestion.jawaban"
                    rows="3"
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </div>
            <div>
              <b-row class="justify-content-between">
                <b-col>
                  <b-button v-if="previousVisible" @click="previous">
                    previous
                  </b-button>
                </b-col>
                <b-col class="row justify-content-end">
                  <b-button v-if="nextVisible" @click="next"> next </b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-header border-0 py-5">
            <b-row class="justify-content-center">
              <b-col cols="2" class="m-2 text-center" v-for="(soal, i) in questions" :key="soal._id">
                  &nbsp;
                <span
                  @click="changeSoal(i)"
                  :class="`btn btn-icon btn-sm symbol ${
                    soal.answer.filter((item) => item.selected).length +
                      (soal.answer ? 1 : 0) >
                    0
                      ? soal.flagged
                        ? 'btn-light-info active'
                        : 'btn-light-primary' + ' active'
                      : soal.flagged
                      ? 'btn-light-info active'
                      : 'btn-secondary'
                  }`"
                >
                  <span
                    :class="`svg-icon svg-icon-md ${
                      soal.flagged ? 'svg-icon-light' : ''
                    }`"
                  >
                    {{ i + 1 }}
                  </span>
                </span>
              </b-col>
            </b-row>
            <!-- <ul
              style="
                list-style: none;
                columns: 4;
                -webkit-columns: 4;
                -moz-columns: 4;
              "
              class="px-2"
            >
              <li v-for="(soal, i) in questions" :key="soal._id">
                &nbsp;
                <span
                  @click="changeSoal(i)"
                  :class="`btn btn-icon btn-sm symbol ${
                    soal.answer.filter((item) => item.selected).length +
                      (soal.answer ? 1 : 0) >
                    0
                      ? soal.flagged
                        ? 'btn-light-info active'
                        : 'btn-light-primary' + ' active'
                      : soal.flagged
                      ? 'btn-light-info active'
                      : 'btn-secondary'
                  }`"
                >
                  <span
                    :class="`svg-icon svg-icon-md ${
                      soal.flagged ? 'svg-icon-light' : ''
                    }`"
                  >
                    {{ i + 1 }}
                  </span>
                </span>
              </li>
            </ul> -->
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import { SIDE_BAR } from "@/core/services/store/htmlclass.module";

import { EXAM_START } from "@/core/services/store/ujian.module";

import { mapGetters, mapState } from "vuex";
import {
  GRADE_ATTEMPT,
  SET_ONE_ATTEMPT,
  QUESTION,
} from "@/core/services/store/attempt.module";

import { GET_ONE_UJIAN } from "@/core/services/store/ujian.module";
import moment from "moment";

import Swal from "sweetalert2";

export default {
  name: "AttemptSoal",
  created() {
    // this.$root.$on("scrollToSoal", (i) => this.scrollToSoal(i));
  },
  watch: {
    selectedIndex: function (newVal) {
      const maxSize = this.questions.length - 1;

      if (newVal == 0) {
        this.previousVisible = false;
      } else {
        this.previousVisible = true;
      }

      if (newVal == maxSize) {
        this.nextVisible = false;
      } else {
        this.nextVisible = true;
      }

      this.selectedQuestion = this.questions[newVal];
    },
  },
  mounted() {
    var body = KTUtil.getById("kt_wrapper");

    this.$store.dispatch(EXAM_START, true);
    this.$store.dispatch(SIDE_BAR, false);

    this.getData();

    if (this.questions.length > 0) {
      this.nextVisible = true;
    }

    KTUtil.removeClass(body, "wrapper");
  },
  destroyed() {
    var body = KTUtil.getById("kt_wrapper");
    KTUtil.addClass(body, "wrapper");
    clearInterval(this.interval);
    this.$store.dispatch(SIDE_BAR, true);
  },
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      interval: null,
      selectedIndex: 0,
      sliding: null,
      questions: [],
      selectedQuestion: {},
      nextVisible: false,
      previousVisible: false,
      currentUjian: {},
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.ujian.errors,
      seconds() {
        return this.currentUjian.time % 60;
      },
      minutes() {
        return Math.trunc(this.currentUjian.time / 60) % 60;
      },
      hours() {
        return Math.trunc(this.currentUjian.time / 60 / 60) % 24;
      },
    }),
    // ...mapGetters(["currentUjian"]),
    idUjian() {
      return this.$route.params.idUjian;
    },
  },
  methods: {
    getData() {
      this.$store
        .dispatch(GET_ONE_UJIAN, this.idUjian)
        .then((response) => {
          this.currentUjian = response.data;

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: this.currentUjian.name },
          ]);

          // const tanggal_dibuka = moment(this.currentUjian.opening_time).format(
          //   "YYYY-MM-DD"
          // );
          // const tanggal_ditutup = moment(this.currentUjian.closing_time).format(
          //   "YYYY-MM-DD"
          // );

          // const start_time = moment(this.currentUjian.opening_time).format(
          //   "HH:mm"
          // );

          // const end_time = moment(this.currentUjian.closing_time).format(
          //   "HH:mm"
          // );

          // this.currentUjian.tanggal_dibuka = tanggal_dibuka;
          // this.currentUjian.tanggal_ditutup = tanggal_ditutup;
          // this.currentUjian.tanggal_dibuka_jam = start_time;
          // this.currentUjian.tanggal_ditutup_jam = end_time;

          // const data = this.currentClassList.data.filter((item) =>
          //   this.currentUjian.class_id.includes(item._id)
          // );

          // this.currentAssignClass = data;
          // this.totalRows = data.length;

          // this.selectedQuestionPack = this.currentUjian.question_pack_id._id;

          this.questions = this.currentUjian.question_pack_id.questions;
          console.log(this.questions);

          this.selectedQuestion = this.questions[this.selectedIndex];

          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    next() {
      this.selectedIndex++;
    },
    previous() {
      if (this.selectedIndex - 1 >= 0) {
        this.selectedIndex--;
      }
    },
    changeSoal(i) {
      this.selectedIndex = i;
    },
  },
};
</script>